.file-input {
  display: none;

  &-label {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    line-height: 1.2;
    border-radius: var(--chakra-radii-md);
    font-weight: var(--chakra-fontWeights-semibold);
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    height: var(--chakra-sizes-10);
    min-width: var(--chakra-sizes-10);
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    background: var(--chakra-colors-orange-500);
    color: var(--chakra-colors-white);
    cursor: pointer;

    &:hover {
      background: var(--chakra-colors-orange-600);
    }
  }
}

.file-uploader {
  border: 2px solid var(--chakra-colors-orange-500) !important;
}
