.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top: 3px solid #000;
  animation: spin 1s linear infinite;

  &--container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
