.status {
  padding: 0.1rem 0.5rem;
  border-width: 1px;
  text-transform: capitalize;

  &--draft {
    background-color: #ffd8ba;
    border-color: #cd725e;
    color: #ac6150;
  }

  &--public {
    background-color: #ccffbd;
    border-color: #80c66c;
    color: #5b8e4d;
  }
}
