.rc-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: #333;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  text-align: left;
  table-layout: fixed;
  overflow-x: auto;

  &-cell {
    padding: 10px 12px;
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-thead th {
    background-color: #edf2f7;
    border-bottom: 0;
    padding: 10px 12px;
    color: #718096;
    font-weight: 500;
  }
}

table {
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
}
